import React from "react";
import GoogleSignin from "../img/btn_google_signin_dark_pressed_web.png";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const [user] = useAuthState(auth);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <div className="w-full fixed h-36 grid grid-cols-4 place-items-center bg-green-400 z-10">
      <a href="/" className="text-4xl text-blue-800"><b>Haikuatry</b></a>
      <div></div>
      <div className="grid place-items-center grid-cols-4">
        <NavLink
          to="/live"
          className={({isActive}) => "text-2xl w-24 text-center " + (isActive ? "text-green-400 bg-blue-800 rounded" : "text-blue-800")}
        >
          Live
        </NavLink>
        <NavLink
          to="/feed"
          className={({isActive}) => "text-2xl w-24 text-center " + (isActive ? "text-green-400 bg-blue-800 rounded" : "text-blue-800")}
        >
          Feed
        </NavLink>
        <NavLink
          to="/post"
          className={({isActive}) => "text-2xl w-24 text-center " + (isActive ? "text-green-400 bg-blue-800 rounded" : "text-blue-800")}
        >
            Post
        </NavLink>
        <NavLink
          to="/profile"
          className={({isActive}) => "text-2xl w-24 text-center " + (isActive ? "text-green-400 bg-blue-800 rounded" : "text-blue-800")}
        >
          Profile
        </NavLink>
      </div>
      {user ? (
          <button onClick={signOut} className="w-28 border-none rounded text-2xl bg-blue-800 text-green-400">
            Sign Out
          </button>
        ) : (
          <img
            onClick={googleSignIn}
            src={GoogleSignin}
            alt="sign in with google"
            type="button"
          />
        )}
    </div>
  );
};
export default NavBar;