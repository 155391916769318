import React, { useEffect, useRef, useState } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SendMessage from "../components/SendMessage";
import HaikuLive from "../components/haikuLive";
import { HiPencilAlt } from "react-icons/hi";
import { IconContext } from "react-icons";

function Live() {
  const [user] = useAuthState(auth);

  const [messages, setMessages] = useState([]);
  const [showMsg, setShowMsg] = useState(false);

  const scroll = useRef();

  useEffect(() => {
    const q = query(
      collection(db, "live"),
      orderBy("createdAt", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, []);

  return (
    <div className="pt-36">
        {user ? (
          <div className={`${showMsg ? `w-[68vw] ml-[26vw]` : `w-[90vw] ml-[8vw]`}`}>
            <span ref={scroll}></span>
            <button className={`w-16 grid place-items-center h-16 rounded-full bg-blue-800 fixed ${showMsg ? `left-[23vw]` : `left-3`} bottom-3`} onClick={() => setShowMsg(showMsg ? false : true)}><IconContext.Provider value={{ size: '30px' }}><HiPencilAlt /></IconContext.Provider></button>
            <div className="">
              {messages?.map((message) => 
                  <HaikuLive haiku={message} />
              )}
            </div>
            {showMsg ? (
              <SendMessage scroll={scroll} />
            ) : (
              <></>
            )}
          </div>
        ) : (
            <h1 className="text-7xl text-green-400 mt-4 ml-8">Sign in to use the chat!</h1>
        )}
    </div>
  );
}

export default Live;