import React from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function HaikuLive({ haiku }) {
    const [user] = useAuthState(auth);

    return(
        <div className={`chat ${user.uid === haiku.uid ? `chat-end` : `chat-start`}`}>
            <img
                src={haiku.avatar}
                className="chat-image avatar w-10 h-10 rounded-full"
                alt="user avatar"
            />
            <div className="chat-bubble text-white bg-blue-800">
                <h1 className="text-green-400 text-3xl mb-4">{haiku.title}</h1>
                <p className="text-lg">{haiku.line1}</p>
                <p className="text-lg">{haiku.line2}</p>
                <p className="text-lg">{haiku.line3}</p>
            </div>
        </div>
    );
}

export default HaikuLive;