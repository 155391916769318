import React from "react";

function Home() {
  return (
    <div className="text-center pt-36 text-2xl text-green-400">
      <p>
        <h1 className="text-7xl text-blue-800">Welcome, you people!</h1><br/>
        This site is Haikuatry;<br/>
        Home of Haiku<br/><br/>

        Haiku are poems<br/>
        With five, then seven, then five<br/>
        Syllables (sound things)<br/><br/>

        Become emotion,<br/>
        Walk through the door of Haiku,<br/>
        Experience art<br/>
      </p>
    </div>
  );
}

export default Home;