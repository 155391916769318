import React from "react";

function Haiku({ haiku }) {
    return(
        <div className="w-full h-fit p-4 border-2 border-green-400 bg-blue-800 text-white grid grid-rows-5 place-items-center">
            <h1 className="text-green-400 text-3xl mb-4">{haiku.title}</h1>
            <div className="grid place-items-center grid-cols-2">
                <img
                    src={haiku.avatar}
                    className="rounded-full h-10 w-10"
                    alt="user avatar"
                />
                <p className="">{haiku.name}</p>
            </div>
            <p className="text-lg">{haiku.line1}</p>
            <p className="text-lg">{haiku.line2}</p>
            <p className="text-lg">{haiku.line3}</p>
        </div>
    );
}

export default Haiku;